 
import { useNavigate } from "react-router-dom";

const Page = () => {
 
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="hero h-screen bg-slate-100">
        <div className="hero-content flex flex-col text-center">
          <h1 className="text-4xl font-bold  my-1">You fell into my trap!</h1>
          <h4 className="text-3xl font-thin  my-1">
            This page hasn't been created yet!
          </h4>
          <div>
            <img
			className="h-96"
              src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/1877accf-e1d9-493e-ad46-3a5bf2ea8305/dg2hdt0-c2290045-1d85-4efe-9ae6-b4d764983499.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzE4NzdhY2NmLWUxZDktNDkzZS1hZDQ2LTNhNWJmMmVhODMwNVwvZGcyaGR0MC1jMjI5MDA0NS0xZDg1LTRlZmUtOWFlNi1iNGQ3NjQ5ODM0OTkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.-hi47BA-CR-0INi-nErsVylQoe9D-HFDEYwsnmjF3uE"
              alt="i_am_sorry"
            ></img>
          </div>
          <div
            role="button"
            onClick={handleButtonClick}
            className="w-44 btn btn-square btn-circle text-primary "
          >
            Go to a safer place !
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
