import React, { useMemo } from "react";
import bgProfile from "@assets/bgs/personal/bgPortifolio.png";
import profilePic from "@assets/bgs/personal/profile.jpg";
import verified from "@assets/verified.png";

const technologies = [
  "JavaScript",
  "React",
  "Node.js",
  "ExpressJS",
  ".Net Core",
  "SQL",
  "NoSQL",
  "Git",
  "Java",
  "CI/CD",
  "Vue",
  "Angular",
];

const InfoSideBar = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:yuri.root+portifolio-contact@email.com";
  };

  const handleLinkedInClick = () => {
    window.location.href = "https://www.linkedin.com/in/yurito/";
  };

  const handClickGithub = () => {
	window.location.href = "https://github.com/yurito"
  }

  return useMemo(() => {
    return (
      <div className="sm:flex-none md:basis-1/4   mx-2">
        <div className="card  sm:w-full  md:w-72  bg-base-100 shadow-xl mb-4 relative ">
          <figure className="relative ">
            <img src={bgProfile} alt="bgProfile" />
            <div className=" absolute  h-full w-full bg-gradient-to-t from-base-100">
              <div className="avatar  absolute bottom-5 pl-5 z-10">
                <div className="w-2/4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img alt="Yuri Farias" src={profilePic} />
                </div>
              </div>
            </div>
          </figure>

          <div className="card-body px-4">
            <div className="card-title ">
              <span>Yuri Farias</span>
              <img className="w-6" src={verified} alt="verified" />
            </div>
            <div className="text-sm flex justify-between font-light">
              <span className="pr-2"> Software Engineer Developer </span>
              <img
                className="w-5 h-5"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Brazilian_Flag_-_round.svg/2048px-Brazilian_Flag_-_round.svg.png"
                alt="Brazil_Flag"
              />
            </div>
            <small className="text-xs">
              Full-stack developer with 10+ years of experience in building web
              apps. Skilled in React, Node.js, ReactNative, NoSQL and SQL.
            </small>
            <div className="flex flex-wrap gap-1 pt-3">
              {technologies.map((tech, index) => (
                <span className="badge text-xs">{tech}</span>
              ))}
            </div>

            <div className="card-actions justify-center pt-5">
              <button className="btn w-full h-4 btn-primary">
                Download CV
              </button>
            </div>
          </div>
        </div>
        <div className="card  sm:w-full  md:w-72  bg-base-100 shadow-xl mb-4">
          <div className="card-body px-4  text-sm">
            <h2 className="card-title font-medium">About me</h2>
            <small className="text-xs">
              In this Year of the Dragon, at the age of 33, I bring over 14
              years of experience as a seasoned senior developer. My passion
              lies in harnessing technology to address real-world challenges.
              Continuously pursuing innovative approaches, I aim to enrich user
              experiences and promote software accessibility for all. I'm
              dedicated to finding new avenues to enhance user experience and
              ensure software accessibility for everyone.
            </small>
            <div className="divider text-sm">Reach out to me</div>

            <div className="card-actions justify-start  flex flex-row pt-5 text-sm">
              <div
                className="flex gap-2 items-center font-semibold cursor-pointer  w-full hover:text-primary"
                onClick={handleEmailClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m17 4.238l-7.928 7.1L4 7.216V19h16zM4.511 5l7.55 6.662L19.502 5z"
                  ></path>
                </svg>
                <span>yuri.root@gmail.com</span>
              </div>
              <div className="flex gap-1 items-center font-semibold cursor-pointer w-full hover:text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.82 9.82 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.23 8.23 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.2 8.2 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18s.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01"
                  ></path>
                </svg>

                <a
                  href="https://wa.me/5582981581872"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>
              </div>
              <div
                className="flex gap-1 items-center font-semibold cursor-pointer  w-full hover:text-primary"
                onClick={handleLinkedInClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                  ></path>
                </svg>
                <span> LinkedIn - yurito</span>
              </div>
              <div
                className="flex gap-1 items-center font-semibold cursor-pointer w-full hover:text-primary"
                onClick={handClickGithub}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 .297c-6.63 0-12 5.373-12 12c0 5.303 3.438 9.8 8.205 11.385c.6.113.82-.258.82-.577c0-.285-.01-1.04-.015-2.04c-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729c1.205.084 1.838 1.236 1.838 1.236c1.07 1.835 2.809 1.305 3.495.998c.108-.776.417-1.305.76-1.605c-2.665-.3-5.466-1.332-5.466-5.93c0-1.31.465-2.38 1.235-3.22c-.135-.303-.54-1.523.105-3.176c0 0 1.005-.322 3.3 1.23c.96-.267 1.98-.399 3-.405c1.02.006 2.04.138 3 .405c2.28-1.552 3.285-1.23 3.285-1.23c.645 1.653.24 2.873.12 3.176c.765.84 1.23 1.91 1.23 3.22c0 4.61-2.805 5.625-5.475 5.92c.42.36.81 1.096.81 2.22c0 1.606-.015 2.896-.015 3.286c0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                  ></path>
                </svg>
                <span> Github - yurito</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card  sm:w-full  md:w-72  bg-base-100 shadow-xl mb-4">
          <div className="card-body px-4  text-sm">
            <h2 className="card-title  font-medium">Information</h2>
            <div className="py-2">
              <ul className="list-none">
                <li className="flex justify-between border-b-2 border-accent-content border-opacity-5 mb-2">
                  <span className="font-light">Location</span>
                  <span>Maceió, AL</span>
                </li>
                <li className="flex justify-between border-b-2 border-accent-content border-opacity-5 mb-2">
                  <span className="font-light">Experience</span>
                  <span>{">10 years"}</span>
                </li>
                <li className="flex justify-between border-b-2 border-accent-content border-opacity-5 mb-2">
                  <span className="font-light">Availability</span>
                  <span>Immediately</span>
                </li>
                <li className="flex justify-between border-b-2 border-accent-content border-opacity-5 mb-0">
                  <span className="font-light">Working Style</span>
                  <span>Fully Remote</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);
};

export default InfoSideBar;
