import React from "react"
import Experience from "./Experience"
import Education from "./Education"


const ExperienceView = () => {


	return (
		<>
			<Experience />
			<div className="my-5"></div>
			<Education/>
		</>
	)

}

export default ExperienceView