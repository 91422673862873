import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Error404 from "../layout/Error404";
import Resume from "../pages/Resume";
import ExperienceView from "../pages/Experiences/ExperienceView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/resume" replace />,
  },
  {
    path: "/",
    Component: Resume,
    loader: ({ params }) => {
      // da para carregar api aqui ver depois os detalhes de como fazer
      return false;
    },
    // Children precisa do outlet para rodar como no landing page ta sem a barra de navegacao nao pega esses caras daqui
    children: [
      { index: true, path: "resume", Component: ExperienceView },
      { index: true, path: "project", Component: Error404 },
      { index: true, path: "blog", Component: Error404 },
    ],
  },
  { path: "**", Component: Error404 },
]);

const RouteRender = () => <RouterProvider router={router} />;

export default RouteRender;
