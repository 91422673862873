import { Outlet } from "react-router-dom";
import InfoSideBar from "../components/InfoSideBar";
import StickNavMenu from "../components/StickNavMenu";

const Resume = () => {

  return (
    <div className="bg-slate-200 ">
      <div className="h-fit md:container md:mx-auto">
        <div className="flex flex-col justify-center md:flex-row  py-3">
          <InfoSideBar />
          <div className="sm:flex-1 lg:flex-initial md:basis-3/4 lg:basis-2/4 mx-2 mb-4">
             <StickNavMenu/>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
