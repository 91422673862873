import React from "react";
import { ContractType, IExperience } from "../../interfaces/IExperiences";
import clipLogo from "@assets/clipLogo.png";
import linxLogo from "@assets/linxLogo.png";
import faracoLogo from "@assets/faracoLogo.png";
import underLogo from "@assets/underLogo.png";
import AtonitLogo from "@assets/atonit_logo.png";
import SalesforceLogo from "@assets/salesForceLogo.png";

const Experience = () => {
  const experiences: Array<IExperience> = [
    {
      jobPeriod: "Sep 2021 - Out 2023",
      companyLogo: SalesforceLogo,
      companyName: "Salesforce",
      companyShortAddress: "San Francisco, CA",
      duties: `Architect, design, implement, test and deliver highly scalable products. 
			Develop test strategies, design automation frameworks, write unit/functional 
			tests to drive up code coverage and automation metrics.
			Analyze, design and develop test cases and implement automated test suites. 
			Design, build, monitor, maintain, and improve the DNS, GSLB infrastructure. 
			Design and architect robust and scalable DNS services.`,
      jobTitle: "Software Engineer MTS",
      contractType: ContractType.FULLTIME,
    },
    {
      jobPeriod: "Out 2021 - Set 2022",
      companyLogo: AtonitLogo,
      companyName: "Atonit",
      companyShortAddress: "São Paulo, SP",
      duties: `
		Designing, developing, and leading customized Salesforce Commerce Cloud (SFCC) solutions; 
		providing technical leadership and guidance; 
		analyzing business requirements; customizing and configuring SFCC applications; 
		integrating with MarketPlace Cloud Computing and other systems; managing data; testing, deploying, 
		and optimizing performance; documenting solutions and providing training; and continuously improving 
		processes and technologies.`,
      jobTitle: "Senior Salesforce Developer",
      contractType: ContractType.FULLTIME,
    },
    {
      jobPeriod: "May 2018 - Sep 2021",
      companyLogo: clipLogo,
      companyName: "Clip",
      companyShortAddress: "Maceió, AL",
      duties: `
		Developing end-to-end solutions using MongoDB, AWS, Google Cloud Platform,
		Angular, Vue.js, React, and React Native; designing, implementing, and maintaining database structures with MongoDB;
		deploying and managing applications on AWS and Google Cloud; building responsive web applications 
		with Angular, Vue.js, and React; creating cross-platform mobile applications with React Native;
		ensuring seamless integration between frontend and backend systems; 
		optimizing performance and scalability of applications; and staying updated with the latest technologies
		and best practices in full-stack development.`,
      jobTitle: "Full Stack Developer",
      contractType: ContractType.FULLTIME,
    },
    {
      jobPeriod: "May 2015 - Apr 2018",
      companyLogo: linxLogo,
      companyName: "Linx",
      companyShortAddress: "Porto Alegre, RS",
      duties: `
		Implementing solutions using .NET technology, SQL, JavaScript, EntityFramework, and AngularJS based on functional
		specifications, research, and development. Developing source code, efficiently maintaining it in a
		reusable and reliable manner. Integrating solutions from legacy infrastructure Storages with
		Azure, high-performance database solutions, and hosting with high availability.
		Ensuring the best possible performance, quality, and responsiveness of applications.
		Identifying system bottlenecks and bugs, reviewing and documenting to mitigate difficulties. 
		Assisting in maintaining code quality, efficiency, and automation.`,
      jobTitle: ".NET Developer",
      contractType: ContractType.FULLTIME,
    },
    {
      jobPeriod: "Dec 2013 - Nov 2014",
      companyLogo: faracoLogo,
      companyName: "Faraco, Azevedo e Muratt Advocacia Empresarial",
      companyShortAddress: "Porto Alegre, RS",
      duties: `
		Providing remote and on-site support to internal users.
		Managing networks, access permissions, Quality of Service (QoS), and data server maintenance.
		Maintaining routine backups with data integrity checks.
		Supervising and assisting with computer network functions and operations.
		Offering technical assistance and training to individuals.
		Installing software upgrades and making modifications to enhance equipment performance.
		Managing inventory of spare parts for equipment.
		Developing, deploying, and supporting information systems and technological solutions.
		Installing and customizing software, administering systems, and configuring network security procedures.`,
      jobTitle: "IT Service Desk Analyst",
      contractType: ContractType.FULLTIME,
    },
    {
      jobPeriod: "Out 2011 - Dec 2013",
      companyLogo: underLogo,
      companyName: "Under",
      companyShortAddress: "Porto Alegre, RS",
      duties: `
		System monitoring, network performance, usage, adjustments, and enhancements to ensure high
		security efficiency and availability levels. Maintenance and configuration across various Linux,
		BSD, and Windows 2003-2012 server distributions on Cloud systems or physical Dedicated Servers.
		Identifying, analyzing, and developing efforts to pinpoint the root cause of technical or systemic difficulties
		on a server and providing a solution. Installing and administering fast web systems for hosting account management,
		such as Plesk, WHM/CPanel control panels. Managing email usage policies, SPF, RFCs, and SPAM control.
		Maintaining and monitoring backup routines for Cloud and Dedicated servers.`,
      jobTitle: "Cloud Solutions Architect",
      contractType: ContractType.FULLTIME,
    },
  ];

  return (
    <div className="card w-full min-h-[40rem] dvh] bg-base-100 shadow-xl mb-4 p-5">
      <div className="text-1xl font-extrabold ">
        <span className="badge badge-xs badge-info mr-2"></span>
        Experience
      </div>
      {experiences.map((experience) => (
        <div className="grid grid-flow-row-dense bg-slate-100 rounded-md grid-cols-6 my-3">
          <div className="flex pt-5 justify-center ">
            <div className="avatar">
              <div className="w-12 h-12 rounded-full ">
                <img
                  src={experience.companyLogo}
                  alt={experience.companyName}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-subgrid gap-3 col-span-5  p-2">
            <div className="flex justify-between text-md">
              <label className="font-bold">{experience.jobTitle}</label>
              <span className="badge badge-primary w-32  lg:w-auto   flex flex-nowrap text-center text-xs">
                {experience.contractType}
              </span>
            </div>
            <div className="flex  flex-wrap  text-xs md:text-sm  font-light">
              <div className="flex  gap-2 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="M7 5V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zM4 16v3h16v-3zm0-2h16V7H4zM9 3v2h6V3zm2 8h2v2h-2z"
                  ></path>
                </svg>
                <span>{experience.companyName}</span>
              </div>
              <div className="flex gap-2  pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="m12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9zM12 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4"
                  ></path>
                </svg>
                {experience.companyShortAddress}
              </div>
              <div className="flex gap-2 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 10H4v8h16zm-9 2v4H6v-4zM7 5H4v4h16V5h-3v2h-2V5H9v2H7z"
                  ></path>
                </svg>
                {experience.jobPeriod}
              </div>
            </div>
            <div className="flex flex-wrap justify-star text-xs xl:text-sm ">
              {experience.duties}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
