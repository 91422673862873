import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const StickNavMenu  = () => {
	const location = useLocation();

	const selectedLocation = location?.pathname;
  
	const routeOptions = {
	  RESUME: "/resume",
	  PROJECT: "/project",
	  BLOG: "/blog",
	};
  
  return (
	<div className="card w-full bg-base-100 shadow-md mb-2 sticky top-0 z-50 mx-auto">
	<ul className="menu menu-horizontal justify-around lg:menu-horizontal bg-base-100 rounded-box">
	  <li
		className={
		  selectedLocation === routeOptions.RESUME
			? "border-b-4 border-primary"
			: ""
		}
	  >
		<Link  to={routeOptions.RESUME}>Resume</Link>
	  </li>
	  <li
		className={
		  selectedLocation === routeOptions.PROJECT
			? "border-b-4 border-primary"
			: ""
		}
	  >
		<Link  to={routeOptions.PROJECT}>Project</Link>
	  </li>
	  <li
		className={
		  selectedLocation === routeOptions.BLOG
			? "border-b-4 border-primary"
			: ""
		}
	  >
		<Link  to={routeOptions.BLOG}>Blog</Link>
	  </li>
	</ul>
  </div>
  );
};

export default StickNavMenu;