import React from "react";
import { ContractType, IExperience } from "../../interfaces/IExperiences";
import unipLogo from "@assets/unipLogo.png";
import unisinosLogo from "@assets/unisinosLogo.png";
import senaiLogo from "@assets/senaiLogo.png"



const styleContractType = (type: ContractType) =>{

	switch (type) {
		case ContractType.HOLD:
			return "badge badge-warning w-32  lg:w-auto   flex flex-nowrap text-center text-xs";
			 
	
		default:
			return "badge badge-success w-32  lg:w-auto   flex flex-nowrap text-center text-xs";
	}
}
const Education = () => {
  const experiences: Array<IExperience> = [
    {
      jobPeriod: "Jun 2017 - Dec 2023",
      companyLogo: unipLogo,
      companyName: "Unip",
      companyShortAddress: "São Paulo, SP",
      duties: `Bachelor's Degree in Analysis and Systems Development: Equips students with skills in software development, database management,
	   and project management. Covers programming, software engineering principles, and system analysis/design methodologies.
	    Provides practical experience through projects and internships. Prepares graduates for careers in information technology.`,
      jobTitle: "Bachelor's Degree in Analysis and Systems Development",
      contractType: ContractType.COMPLETED,
    },
    {
      jobPeriod: "Feb 2012 - On Hold",
      companyLogo: unisinosLogo,
      companyName: "Unisinos",
      companyShortAddress: "São Leopoldo, RS",
      duties: `Bachelor's Degree in Digital Gaming: Designed to foster expertise in game design, development, and production.
	   Offers comprehensive training in game programming, graphics design, interactive storytelling, and game mechanics.
	   Emphasizes hands-on learning through practical projects and industry collaborations, providing students with real-world experience.
	    Prepares graduates for diverse roles in the dynamic and evolving field of digital gaming, including game development, design, and production.`,
      jobTitle: "Bachelor's Digital Gaming",
      contractType: ContractType.HOLD,
    },
	{
		jobPeriod: "Jun 2008 - Jul 2010",
		companyLogo: senaiLogo,
		companyName: "Senai",
		companyShortAddress: "Porto Alegre, RS",
		duties: ` Completed comprehensive training in designing, implementing,
		and maintaining computer networks. Proficient in configuring network devices,
		 troubleshooting connectivity issues, and ensuring network security. 
		 Skilled in network protocols, routing and switching, and network administration.
		  Ready to apply expertise in entry-level positions within the IT industry.`,
		jobTitle: "Network Systems Technician",
		contractType: ContractType.COMPLETED,
	  },
  ];

  return (
    <div className="card w-full min-h-fit dvh] bg-base-100 shadow-xl mb-4 p-5">
      <div className="text-1xl font-extrabold ">
        <span className="badge badge-xs badge-info mr-2"></span>
        Education
      </div>
      {experiences.map((experience, index) => (
        <div className="grid grid-flow-row-dense bg-slate-100 rounded-md grid-cols-6 my-3">
          <div className="flex pt-5 justify-center ">
            <div className="avatar">
              <div className="w-12 h-12 rounded-full ">
                <img
                  className="w-full"
                  src={experience.companyLogo}
                  alt={experience.companyName}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-subgrid gap-3 col-span-5  p-2">
            <div className="flex justify-between text-md">
              <label className="font-bold">{experience.jobTitle}</label>
              <span className={styleContractType(experience.contractType)}>
                {experience.contractType}
              </span>
            </div>
            <div className="flex  flex-wrap  text-xs md:text-sm  font-light">
              <div className="flex  gap-2 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="M7 5V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zM4 16v3h16v-3zm0-2h16V7H4zM9 3v2h6V3zm2 8h2v2h-2z"
                  ></path>
                </svg>
                <span>{experience.companyName}</span>
              </div>
              <div className="flex gap-2  pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="m12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9zM12 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4"
                  ></path>
                </svg>
                {experience.companyShortAddress}
              </div>
              <div className="flex gap-2 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path
                    fill="currentColor"
                    d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 10H4v8h16zm-9 2v4H6v-4zM7 5H4v4h16V5h-3v2h-2V5H9v2H7z"
                  ></path>
                </svg>
                {experience.jobPeriod}
              </div>
            </div>
            <div className="flex flex-wrap justify-star text-xs xl:text-sm ">
              {experience.duties}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Education;
