export interface IExperience {
  companyLogo: string;
  companyName: string;
  companyShortAddress: string;
  duties: string;
  jobTitle: string;
  jobPeriod: string;
  contractType: ContractType;
 
}

export enum ContractType {
  FULLTIME = "Full-Time",
  CONTRACT = "Contract",
  COMPLETED = 'Completed',
  HOLD = "On Hold",
}
